import { employeeinfo } from "../commonDynmicForms/employee_info";
import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";

export default {
    fillable_by:FillableBy.Both,
    title:
        "Clinical Competency Checklist for LivaNova 3T Heater-Cooler Device",
    sections: [
        // {...employeeinfo},
        {
            title: "Device Setup",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Performs initial inspection of the device for cleanliness and physical integrity.",
                "Verifies power connections and confirms the system powers on correctly.",
                "Ensures proper connection of tubing and components for patient use.",
                "Fills water reservoir with sterile or distilled water according to manufacturer’s guidelines.",
                "Verifies correct temperature settings and functionality of all controls."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Operation",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Starts the device and monitors functionality during operation.",
                "Maintains desired temperature range throughout the procedure.",
                "Monitors water levels and refills as necessary during operation.",
                "Demonstrates knowledge of safety features and emergency shutdown procedures.",
                "Responds appropriately to alarms or irregularities during use."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Safety and Infection Control",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Ensures sterile or distilled water is used to prevent contamination.",
                "Adheres to proper water exchange procedures to prevent microbial growth.",
                "Verifies the device is positioned to minimize potential aerosolization risks.",
                "Follows all manufacturer recommendations for infection prevention.",
                "Communicates with the surgical team regarding infection control practices."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Cleaning and Maintenance",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Performs routine cleaning and disinfection according to the manufacturer's guidelines.",
                "Logs all cleaning and disinfection activities in the maintenance record.",
                "Replaces filters and other consumable components at specified intervals.",
                "Documents all maintenance activities, including filter replacements and repairs.",
                "Demonstrates proficiency in using the manufacturer’s cleaning agents and equipment."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Documentation",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Records pre-use checks and calibration settings.",
                "Logs all cleaning, disinfection, and maintenance tasks in the designated logbook.",
                "Maintains accurate records of water changes and component replacements.",
                "Documents any issues or malfunctions for troubleshooting and reporting.",
                "Ensures maintenance logs comply with regulatory and manufacturer requirements."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields:getSignatures(FillableBy.Both)
        },
    ],
}